import { useParams } from "react-router-dom";
import { lookerRoutes } from "lookerRoutes";

export function useCurrentLookerRoute() {
  const params = useParams();

  const category = lookerRoutes.find((item) => item.slug === params.category);
  const route = category?.items.find((item) => item.slug === params.sub);

  return {
    category,
    route,
  };
}
