import React from "react";
import { api } from "api";
import { logout } from "auth";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function SessionExpired() {
  const location = useLocation();
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    console.log("SessionExpired useEffect", location);
    api
      .getUserRoutes()
      .then(() => {
        setIsExpired(false);
      })
      .catch((e) => {
        if (e.status === 403 || e.status === 401) {
          setIsExpired(true);
        }
      });
  }, [location]);

  if (!isExpired) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        background: "#fee9a2",
      }}
    >
      <span onClick={() => logout()} style={{ cursor: "pointer" }}>
        Your session has expired. Please{" "}
        <span style={{ textDecoration: "underline" }}>login</span> again.
      </span>
    </div>
  );
}
