export const lookerRoutes = [
  {
    label: "Business Performance",
    slug: "business-performance",
    icon: "format_list_bulleted_icon",
    items: [
      {
        label: "Network Business Summary",
        slug: "network-business-summary",
        disabled: true,
        fallBack: "/Report/#network_business_summary",
        thumbnail: "/images/network_business_summary.png",
      },
      {
        label: "Churn Forecasting",
        slug: "churn-forecasting",
        disabled: true,
        fallBack: "/Report/#churn_forecasting",
        thumbnail: "/images/churn_forecasting.jpg",
      },
    ],
  },
  {
    label: "Marketing",
    slug: "marketing",
    icon: "tornado",
    items: [
      {
        label: "Funnel 2.0",
        slug: "funnel-2-0",
        thumbnail: "/images/funnel_20.png",
        disabled: true,
        fallBack: "/Report/#funnel_2.0",
        items: [
          // {
          //   label: "Sales Funnel 2.0",
          //   slug: "sales-funnel-2-0",
          //   src: "/embed/dashboards/27",
          // },
          // {
          //   label: "Pulse Dashboard",
          //   slug: "pulse-dashboard",
          //   // src: "/embed/dashboards/YHN20oe7XHnvcVnaa71jqi",
          // },
        ],
      },
      {
        label: "Sales Funnel",
        slug: "sales-funnel",
        src: "/embed/dashboards/43",
        thumbnail: "/images/sales_funnel.png",
      },
      {
        label: "Sales Funnel by Unit",
        slug: "sales-funnel-by-unit",
        disabled: true,
        fallBack: "/Report/#everything_bagel/sales_funnel",
        thumbnail: "/images/sales_funnel_by_unit.png",
      },
    ],
  },
  {
    label: "Sales",
    slug: "sales",
    icon: "history_edu",
    items: [
      {
        label: "Current Pipeline",
        slug: "current-pipeline",
        disabled: true,
        fallBack: "/Report/#pipeline/current",
        thumbnail: "/images/current_pipeline.png",
      },
      {
        label: "Opportunity Conversion",
        slug: "opportunity-conversion",
        // src: "/embed/dashboards/30",
        thumbnail: "/images/opportunity_conversion.png",
        disabled: true,
        fallBack: "/Report/#opportunity_conversion",
      },
      {
        label: "Sales Transaction Detail",
        slug: "sales-transaction-detail",
        src: "/embed/dashboards/44",
        thumbnail: "/images/sales_transaction_detail.png",
      },
      {
        label: "Renewals Reporting",
        slug: "renewals-reporting",
        thumbnail: "/images/renewals_reporting.png",
        items: [
          {
            label: "Incomplete Renewals",
            slug: "incomplete-renewals",
            src: "/embed/dashboards/5",
          },
          {
            label: "Account + Renewals",
            slug: "account-renewals",
            src: "/embed/dashboards/61?Date+Month+Date=this+month",
          },
        ],
      },
    ],
  },
  {
    label: "Inventory",
    slug: "inventory",
    icon: "event_seat",
    items: [
      {
        label: "Optics Floorplans",
        slug: "optics-floorplans",
        src: "/embed/dashboards/66",
        thumbnail: "/images/optics_floorplans.png",
      },
      {
        label: "Optics Pricing Tool",
        slug: "optics-pricing-tool",
        disabled: true,
        fallback:
          "https://drive.google.com/drive/u/0/folders/1XFolAgHKTGkvyVW6P_iCKX-hCt1MZKms",
        thumbnail: "/images/optics_pricing_tool.png",
      },
      {
        label: "Office Availability",
        slug: "office-availability",
        src: "/embed/dashboards/70",
        thumbnail: "/images/office_availability.png",
      },
      {
        label: "Location Yellow Pages",
        slug: "location-yellow-pages",
        thumbnail: "/images/location_yellow_pages.png",
        items: [
          {
            label: "All location Info",
            slug: "all-location-info",
            src: "/embed/dashboards/lWhZDfmWms5tBsLaHsegns",
          },
          {
            label: "Location Quick Reference",
            slug: "location-quick-reference",
            src: "/embed/dashboards/Q437L2YjUk4mHRsWTaSD4V",
          },
          {
            label: "Operations and Contact Info",
            slug: "operations-and-contact-info",
            src: "/embed/dashboards/dEoDVZM25fXruRoc1wzSxM?",
          },
          {
            label: "Address",
            slug: "address",
            src: "/embed/dashboards/letXWksm1F1SDwMbEchVOo?",
          },
          {
            label: "Finance Team",
            slug: "finance-team",
            src: "/embed/dashboards/FZjhl5LVYRuxTSKcrjC1Po?",
          },
        ],
      },
      {
        label: "Inventory Roll",
        slug: "inventory-roll",
        src: "/embed/dashboards/47",
        thumbnail: "/images/outstanding_coupa_items.jpg",
      },
    ],
  },
  {
    label: "Churn",
    slug: "churn",
    icon: "heart_broken",
    items: [
      {
        label: "Churn & NRR",
        slug: "churn-and-nrr",
        thumbnail: "/images/churn_nrr.jpg",
        items: [
          {
            label: "MEMBER CHURN (CLOSURE-ADJUSTED)",
            slug: "member-churn-closure-adjusted",
            src: "/embed/dashboards/62",
          },
          {
            label: "KPI",
            slug: "kpi",
            src: "/embed/dashboards/40",
          },
          {
            label: "Gain / Loss",
            slug: "gain-loss",
            src: "/embed/dashboards/48",
          },
          {
            label: "Amortized INCR.MCV / INCR.FORECAST",
            slug: "amortized-incr-mcv-incr-forecast",
            src: "/embed/dashboards/50",
          },
          {
            label: "NPS",
            slug: "nps",
            src: "/embed/dashboards/45",
          },
          {
            label: "Coccupancy",
            slug: "occupancy",
            src: "/embed/dashboards/46",
          },
        ],
      },
      {
        label: "Member Exit Reasons",
        slug: "member-exit-reasons",
        disabled: true,
        fallBack: "/Report/#member_exit_reasons",
        thumbnail: "/images/member_exit_reason.jpg",
      },
    ],
  },
  {
    label: "Revenue",
    slug: "revenue",
    icon: "attach_money",
    items: [
      {
        label: "Bagel MCV",
        slug: "bagel-mcv",
        disabled: true,
        fallBack: "/Report/#everything_bagel/occupied_mcv",
        thumbnail: "/images/bagel.png",
      },
      {
        label: "Billing Detail",
        slug: "billing-detail",
        thumbnail: "/images/billing_details.png",
        items: [
          {
            label: "Invoice Details (Zuora)",
            slug: "invoice-details-zuora",
            src: "/embed/dashboards/59",
          },
          {
            label: "Overdue Invoices (Zuora)",
            slug: "overdue-invoices-zuora",
            src: "/embed/dashboards/54",
          },
          {
            label: "MXM Invoice Review",
            slug: "mxm-invoice-review",
            src: "/embed/dashboards/53",
          },
        ],
      },
    ],
  },
  {
    label: "Unit Performance",
    slug: "unit-performance",
    icon: "business",
    items: [
      {
        label: "Detailed Weekly Pulse",
        slug: "detailed-weekly-pulse",
        thumbnail: "/images/weekly_pulse.png",
        items: [
          {
            label: "KPI",
            slug: "kpi",
            src: "/embed/dashboards/40",
          },
          {
            label: "Gain / Loss",
            slug: "gain-loss",
            src: "/embed/dashboards/48",
          },
          {
            label: "Amortized INCR.MCV / INCR.FORECAST",
            slug: "amortized-incr-mcv-incr-forecast",
            src: "/embed/dashboards/50",
          },
          {
            label: "MEMBER CHURN (CLOSURE-ADJUSTED)",
            slug: "member-churn-closure-adjusted",
            src: "/embed/dashboards/62",
          },
          {
            label: "NPS",
            slug: "nps",
            src: "/embed/dashboards/45",
          },
          {
            label: "Coccupancy",
            slug: "occupancy",
            src: "/embed/dashboards/46",
          },
        ],
      },
      {
        label: "Occupancy Reporting",
        slug: "occupancy-reporting",
        disabled: true,
        fallBack: "/Report/#everything_bagel/occupancy",
        thumbnail: "/images/occupancy_reporting.png",
      },
      {
        label: "Launch Reporting",
        slug: "launch-reporting",
        disabled: true,
        fallBack: "/Report/#everything_bagel/occupancy",
        thumbnail: "/images/launch_reporting.png",
      },
      {
        label: "Location Pulse",
        slug: "location-pulse",
        disabled: true,
        fallBack: "/Report/#launch_reporting",
        thumbnail: "/images/location_pulse.png",
      },
      {
        label: "Location Health",
        slug: "location-health",
        disabled: true,
        fallBack: "/Report/#location_health",
        thumbnail: "/images/location_health.png",
      },
      {
        label: "NPS",
        slug: "nps",
        disabled: true,
        fallBack: "/Report/#nps_reports",
        thumbnail: "/images/nps.png",
      },
      {
        label: "Utilization Reporting",
        slug: "utilization-reporting",
        disabled: true,
        fallBack: "/Report/#mxm_wifi_reporting",
        thumbnail: "/images/utilization_reporting.jpg",
      },
      {
        label: "Pace Model",
        slug: "pace-model",
        disabled: true,
        fallBack: "/Report/#pace_model",
      },
      {
        label: "Rev Mgmt Dash",
        slug: "rev-mgmt-dash",
        // src: "/embed/dashboards/68",
        disabled: true,
        fallBack: "/Report/#rev_mgmt_dash",
        thumbnail: "/images/sales_funnel_by_unit.png",
      },
    ],
  },
  {
    label: "Expenses",
    slug: "expenses",
    icon: "shopping_cart",
    items: [
      {
        label: "LCoS Tracker",
        slug: "lcos-tracker",
        src: "/embed/dashboards/23",
        thumbnail: "/images/locos.jpg",
      },
      {
        label: "Outstanding Coupa Items",
        slug: "outstanding-coupa-items",
        thumbnail: "/images/outstanding_coupa_items.jpg",
        items: [
          {
            label: "Invoices Pending Receipts",
            slug: "invoices-pending-receipts",
            src: `/embed/dashboards/74`,
          },
          {
            label: "Outstanding CC Expenses",
            slug: "outstanding-cc-expenses",
            src: `/embed/dashboards/25`,
          },
          {
            label: "Requisitions",
            slug: "requisitions",
            src: `/embed/dashboards/76`,
          },
        ],
      },
    ],
  },
];
