import axios from "axios";

export const agent = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

export const api = {
  getLookerEmbed(src) {
    return agent.request({
      url: "/looker/auth",
      params: {
        src,
      },
    });
  },
  getUserRoutes() {
    return agent.request({
      url: "/optics/api/v1/getUserRoutes",
    });
  },
};
