import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Icon,
  AppBar,
  Toolbar,
  // Button,
  Drawer,
  Box,
  Divider,
  List,
  ListItemButton,
  Collapse,
  ListItemText,
  Avatar,
  ListItemAvatar,
  IconButton,
} from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {
  ExitToApp,
  // Menu as MenuIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
// import { Star, StarBorder } from "@mui/icons-material";
import tableauIcon from "assets/img/tableau.png";
import {
  drawerWidth,
  containerFluid,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  roseColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
  IndustriousColor,
} from "assets/jss/Analytics"; //Styles
import { useOpticStore, useSidebarStore } from "stores/optics";
import Logo from "assets/img/logo.png";
import { lookerRoutes } from "lookerRoutes";

/* Style Variables*/
const pageStyles = () => ({
  blackBackground: {
    color: whiteColor,
    "&:after": { background: blackColor, opacity: ".8" },
  },
  blueBackground: {
    color: whiteColor,
    "&:after": { background: infoColor[0], opacity: ".93" },
  },
  whiteBackground: {
    color: grayColor[2],
    "&:after": { background: whiteColor, opacity: ".93" },
  },
  whiteAfter: {
    "&:after": { backgroundColor: "hsla(0,0%,71%,.3) !important" },
  },
  dividers: {
    content: '""',
    position: "absolute",
    bottom: "0",
    height: "1px",
    right: "15px",
    width: "calc(100% - 30px)",
    backgroundColor: "hsla(0,0%,100%,.3)",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    padding: "8px 0px",
    margin: "0",
    position: "relative",
    zIndex: "4",
  },
  logoImage: {
    transition: "all 300ms linear",
    opacity: 1,
    marginLeft: "15px",
    color: "inherit",
  },
  logoText: {
    ...defaultFont,
    fontSize: "2.6rem",
    minWidth: "unset",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "30px",
    transition: "all 300ms linear",
    opacity: "1",
    transform: "translate3d(0px, 0, 0)",
    padding: "5px",
    "&,&:hover,&:focus": { color: "inherit" },
  },
  img: { width: "35px", verticalAlign: "middle", border: "0" },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    transition: "all 300ms linear",
  },
  list: {
    padding: "0px",
    margin: "8px 0px 0px 0px",
    listStyle: "none",
    color: "inherit",
    "&:before,&:after": { display: "table", content: '" "' },
    "&:after": { clear: "both" },
  },
  item: {
    position: "relative",
    display: "block",
    margin: "2px 0px",
    padding: "0",
    color: "inherit",
  },
  userItem: { "&:last-child": { paddingBottom: "0px" } },
  itemLink: {
    transition: "all 300ms linear",
    display: "block",
    padding: "10px 10px",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
      boxShadow: "none",
    },
    "&,&:hover,&:focus": { color: "inherit" },
  },
  itemIcon: {
    color: "inherit",
    width: "30px",
    height: "24px",
    float: "left",
    position: "inherit",
    top: "3px",
    marginRight: "10px",
    textAlign: "center",
    verticalAlign: "middle",
    opacity: "0.8",
  },
  BookMarkIcon: {
    color: "inherit",
    display: "inherit",
    opacity: ".8",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    margin: "0",
  },
  itemText: {
    color: "inherit",
    ...defaultFont,
    margin: "0",
    lineHeight: ".9em",
    fontSize: "14.5px",
    fontWeight: "400",
    transform: "translate3d(0px, 0, 0)",
    opacity: "1",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    position: "relative",
    display: "inline-block",
    height: "auto",
    whiteSpace: "nowrap",
  },
  userItemText: {},
  collapseList: { marginTop: "0", "& $caret": { marginTop: "8px" } },
  collapseItem: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    margin: "4px 0px",
    padding: "0",
  },
  collapseActive: {
    outline: "none",
    backgroundColor: "rgba(" + hexToRgb(grayColor[17]) + ", 0.2)",
    boxShadow: "none",
  },
  animatedItem: { animation: `$shine 1s 5` },
  "@keyframes shine": {
    "0%": { color: "#FFF", textshadow: "none" },
    "100%": { color: "#FFF", backgroundColor: IndustriousColor },
  },
  collapseItemLink: {
    transition: "all 300ms linear",
    margin: "0 10px 0 5px",
    borderRadius: "3px",
    display: "block",
    padding: "10px",
    backgroundColor: "transparent",
    ...defaultFont,
    width: "auto",
    "&:hover": {
      outline: "none",
      backgroundColor: IndustriousColor,
      boxShadow: "none",
    },
    "&,&:hover,&:focus": { color: "inherit" },
  },
  collapseItemText: {
    color: "inherit",
    ...defaultFont,
    marginLeft: "35px",
    position: "relative",
    transform: "translateX(0px)",
    opacity: "1",
    whiteSpace: "nowrap",
    display: "block",
    transition: "transform 300ms ease 0s, opacity 300ms ease 0s",
    fontSize: "13px",
  },
  caret: {
    marginTop: "13px",
    position: "absolute",
    right: "8px",
    transition: "all 150ms ease-in",
    display: "inline-block",
    width: "0",
    height: "0",
    marginLeft: "2px",
    verticalAlign: "middle",
    borderTop: "4px solid",
    borderRight: "4px solid transparent",
    borderLeft: "4px solid transparent",
  },
  userCaret: { marginTop: "10px" },
  caretActive: { transform: "rotate(180deg)" },
  purple: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      ...primaryBoxShadow,
    },
  },
  blue: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        infoColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(infoColor[0])},.2)`,
    },
  },
  green: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        successColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(successColor[0])},.2)`,
    },
  },
  orange: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        warningColor[0]
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(warningColor[0])},.2)`,
    },
  },
  red: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: IndustriousColor,
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        IndustriousColor
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(IndustriousColor)},.2)`,
    },
  },
  Industrious: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: IndustriousColor,
      boxShadow: `0 12px 20px -10px rgba(${hexToRgb(
        IndustriousColor
      )},.28), 0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.12), 0 7px 8px -5px rgba(${hexToRgb(IndustriousColor)},.2)`,
    },
  },
  white: {
    "&,&:hover,&:focus": {
      color: grayColor[2],
      backgroundColor: whiteColor,
      boxShadow: `0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.14), 0 7px 10px -5px rgba(${hexToRgb(grayColor[2])},.4)`,
    },
  },
  rose: {
    "&,&:hover,&:focus": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow: `0 4px 20px 0 rgba(${hexToRgb(
        blackColor
      )},.14), 0 7px 10px -5px rgba(${hexToRgb(roseColor[0])},.4)`,
    },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 75px)",
    overflow: "auto",
    zIndex: "4",
    overflowScrolling: "touch",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    color: "inherit",
    paddingBottom: "30px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  user: {},
  photo: {
    transition: "all 300ms linear",
    width: "34px",
    height: "34px",
    overflow: "hidden",
    float: "left",
    zIndex: "5",
    marginRight: "11px",
    borderRadius: "50%",
    marginLeft: "15px",
    ...boxShadow,
  },
  avatarImg: { width: "100%", verticalAlign: "middle", border: "0" },
  userCollapseButton: {
    margin: "0",
    padding: "6px 15px",
    "&:hover": { background: "none" },
  },
  container: { ...containerFluid, minHeight: "50px" },
  dark: {
    backgroundColor: grayColor[9],
    color: whiteColor,
    boxShadow: `0 4px 20px 0px rgba(${hexToRgb(
      blackColor
    )}, 0.14), 0 7px 12px -5px rgba(${hexToRgb(grayColor[9])}, 0.46)`,
  },
  transparent: {
    backgroundColor: "transparent",
    color: whiteColor,
    boxShadow: "none",
    paddingTop: "25px",
  },
  sidebarMiniIcon: { width: "20px", height: "17px" },
  justIcon: { paddingLeft: "12px", paddingRight: "12px", fontSize: "20px" },
});

const useStyles = makeStyles(pageStyles);

function SideBarBrand() {
  const classes = useStyles();
  const sideNavMini = useSidebarStore((state) => state.sideNavMini);

  return (
    <div className={classes.logoContainer}>
      <a href="/" className={classes.logoImage}>
        <img
          src={Logo}
          alt="logo"
          style={{ width: "35px", verticalAlign: "middle", border: "0" }}
        />
      </a>
      {!sideNavMini ? (
        <a href="/" className={classes.logoText}>
          Analytics
        </a>
      ) : null}
      <Divider className={classes.dividers} />
    </div>
  );
}

function SideBarUser() {
  const classes = useStyles();
  const navStyles = {
    navButton: { padding: "0.25rem 0.7rem", borderRadius: 4 },
    hoverDefault: { ":hover": { backgroundColor: `rgba(255, 255, 255, 0.2)` } },
    hoverIndustrious: {
      ":hover": { backgroundColor: `rgba(255, 255, 255, 0.2)` },
    },
    navText: { m: 0, fontSize: "1rem", lineHeight: 0 },
    avatarIcon: { width: "1.9rem", height: "1.9rem", fontWeight: "400" },
    avatarListItem: { mr: 1, minWidth: "2.5rem" },
  };

  const userProfile = useOpticStore((state) => state.user);
  const openAvatar = useSidebarStore((state) => state.avatarOpen);
  const toggleAvatar = useSidebarStore((state) => state.toggleAvatar);
  const sideNavMini = useSidebarStore((state) => state.sideNavMini);
  const apiURl = process.env.REACT_APP_API_URL;

  const redirectTableau = (e) => {
    e.preventDefault();
    window.open(
      "https://us-east-1.online.tableau.com/#/site/industriousoffice/explore",
      "_blank"
    );
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear(); // clear storage
    window.open(apiURl + "/auth/logout", "_self");
  };

  return (
    <div style={{ padding: ".5rem 0", position: "relative" }}>
      <List component="nav" aria-label="user-nav-section" sx={{ p: 0 }}>
        {/* This section is the actual button with the icon and the user name */}
        <ListItemButton
          key={"userAvatar"}
          onClick={toggleAvatar}
          sx={{
            ...navStyles.navButton,
            ...navStyles.hoverDefault,
            margin: sideNavMini ? "0.2rem 0.2rem" : "0.2rem 0.7rem",
          }}
        >
          <ListItemAvatar sx={{ ...navStyles.avatarListItem, display: "flex" }}>
            <Avatar
              alt={userProfile?.fullName}
              sx={{ ...navStyles.avatarIcon }}
              src={userProfile?.pictureURL}
            />
          </ListItemAvatar>
          <ListItemText
            primary={userProfile?.fullName}
            disableTypography={true}
            sx={{
              ...navStyles.navText,
              display: sideNavMini ? "none" : "block",
            }}
          />
          {openAvatar ? (
            <ExpandLess sx={{ fontSize: "1.25rem" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "1.25rem" }} />
          )}
        </ListItemButton>
        {/* This section contains the actual elements for said list */}
        <Collapse in={openAvatar} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              key={"tableauButton"}
              onClick={redirectTableau}
              sx={{ ...navStyles.navButton, ...navStyles.hoverIndustrious }}
            >
              <ListItemAvatar
                sx={{ ...navStyles.avatarListItem, display: "flex" }}
              >
                <Avatar src={tableauIcon} sx={{ ...navStyles.avatarIcon }} />
              </ListItemAvatar>
              <ListItemText
                primary={"Tableau"}
                disableTypography={true}
                sx={{ ...navStyles.navText }}
              />
            </ListItemButton>
            <ListItemButton
              key={"LogOutButton"}
              onClick={logout}
              sx={{ ...navStyles.navButton, ...navStyles.hoverIndustrious }}
            >
              <ListItemAvatar
                sx={{ ...navStyles.avatarListItem, display: "flex" }}
              >
                <ExitToApp
                  sx={{
                    width: "1.9rem",
                    height: "1.9rem",
                    alignContent: "center",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={"Sign Out"}
                disableTypography={true}
                className={classes.itemText}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <Divider className={classes.dividers} />
    </div>
  );
}

function SideBarHeader() {
  const styles = {
    sideBar: { mt: 1, border: 0, zIndex: "tooltip", boxShadow: 0, height: 60 },
    sideBarLocation: { ml: "12px", mr: "15px" },
    sideBarButton: {
      height: 41,
      width: 41,
      minWidth: 41,
      p: 0,
      border: 2,
      borderRadius: 1,
      borderColor: IndustriousColor,
      color: IndustriousColor,
    },
    sideBarMiniButton: {
      height: 48,
      width: 48,
      minWidth: 41,
      p: 0,
      borderRadius: 5,
      borderColor: IndustriousColor,
      backgroundColor: "#122932",
      color: whiteColor,
    },
    sideBarSettingsButton: {
      height: 41,
      width: 41,
      minWidth: 41,
      p: 0,
      mr: 1,
      border: 0,
      borderRadius: 1,
      color: "IndustriousColor",
    },
    sideBarButtonHove: {
      ":hover": {
        backgroundColor: "#1E3B47",
        color: whiteColor,
        boxShadow: `0 14px 26px -12px rgba(${hexToRgb(
          IndustriousColor
        )}, 0.42), 0 4px 23px 0px rgba(${hexToRgb(
          blackColor
        )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(IndustriousColor)}, 0.2)`,
      },
    },
  };
  const isSideNavMini = useSidebarStore((state) => state.isSideNavMini);
  const sideNavMini = useSidebarStore((state) => state.sideNavMini);
  const toggleSideNavMini = useSidebarStore((state) => state.toggleSideNavMini);

  if (!isSideNavMini) {
    // disabling this for now
    return null;
  }

  return (
    <AppBar
      position="absolute"
      color="transparent"
      sx={{
        ...styles.sideBar,
        width: {
          xs: sideNavMini
            ? `calc(100% - 80px)`
            : `calc(100% - ${drawerWidth + 20}px)`,
          xl: sideNavMini
            ? `calc(100% - 80px)`
            : `calc(100% - ${drawerWidth + 20}px)`,
        },
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          ...styles.sideBarLocation,
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          disableRipple
          onClick={toggleSideNavMini}
          sx={{
            ...styles.sideBarMiniButton,
            ...styles.sideBarButtonHove,
            display: "flex",
          }}
        >
          <Icon fontSize="medium">
            {isSideNavMini ? (
              <KeyboardDoubleArrowRightIcon />
            ) : (
              <KeyboardDoubleArrowLeftIcon />
            )}
          </Icon>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

function SideBarLinks() {
  // const [showReportNavText, setShowReportNavText] = useState(false);
  const fetchUserRoutes = useOpticStore((state) => state.fetchUserRoutes);
  useEffect(() => {
    fetchUserRoutes();
  }, []);
  // Styles Variable
  const classes = useStyles();
  const styles = {
    button: { padding: "0.25rem 0.7rem", borderRadius: 4 },
    insideButton: {
      padding: "0.1rem 0.7rem",
      margin: "0.1rem 0rem 0.1rem 2.2rem",
      borderRadius: 2,
    },
    iconButton: {
      padding: "0.15rem",
      margin: "0.25rem",
      borderRadius: 6,
      color: whiteColor,
    },
    hoverDefault: { ":hover": { backgroundColor: `rgba(255, 255, 255, 0.2)` } },
    hoverIndustrious: { ":hover": { backgroundColor: IndustriousColor } },
    navText: {
      marginLeft: ".5rem",
      fontSize: "0.9rem",
      lineHeight: 0,
      fontWeight: "400",
    },
    avatarIcon: { width: "2.25rem", height: "2.25rem", fontWeight: "400" },
    avatarListItem: { minWidth: "2.25rem" },
    insideAvatarListItem: { ml: 0, minWidth: "2rem", fontWeight: "300" },
  };
  const navigate = useNavigate();
  const userRoutes = useOpticStore((state) => state.userRoutes); //Grab Routes from State
  const myReportsGroup = useOpticStore((state) => state.myReportsGroup); //Grab the information for my reports group
  // const toggleMyReport = useOpticStore((state) => state.toggleMyReport); //Toggle My Reports
  const fetchTableauToken = useOpticStore((state) => state.fetchTableauToken);
  // const updateMyReport = useOpticStore((state) => state.updateMyReport);
  const isSideNavMini = useSidebarStore((state) => state.isSideNavMini);
  const sortedRoutes = Object.keys(userRoutes)
    .map((key) => {
      return userRoutes[key];
    })
    .sort((a, b) => (a.links_repo_id > b.links_repo_id ? 1 : -1));
  const userReports = Object.keys(sortedRoutes)
    .filter((key) => sortedRoutes[key].is_my_report === true)
    .reduce((res, key) => ((res[key] = sortedRoutes[key]), res), {}); //Filter to User Routes that should appear on myReports
  // const reportRoutes = Object.keys(sortedRoutes).reduce((res, key) => (res[key] = sortedRoutes[key], res), {});
  const reportRoutes = Object.keys(sortedRoutes)
    .filter(
      (key) =>
        // sortedRoutes[key].is_my_report !== true &&
        sortedRoutes[key].sidebar_enabled === true
    )
    .reduce((res, key) => ((res[key] = sortedRoutes[key]), res), {}); //Filter to the Rest of the Routes
  const sideNavMini = useSidebarStore((state) => state.sideNavMini);
  const location = useLocation();
  const { pathname, hash } = location;
  const activeReport = pathname + hash;
  const handleNavButtonClick = (e, route) => {
    e.preventDefault();
    const {
      link_type,
      link_name,
      pathname,
      report_height,
      url,
      key,
      group_name,
    } = route;

    if (link_type === "tableau") {
      //Set the report details
      const ReportDetails = {
        ReportHeight: report_height,
        ReportID: key,
        ReportSubTitle: link_name,
        ReportTableauLink: url,
        ReportTitle: group_name,
      };
      useOpticStore.setState({ tableauReport: ReportDetails }); //store them in the state manager
      fetchTableauToken();
      navigate(pathname);
      if (window.innerWidth <= 1600) {
        useSidebarStore.setState({ sideNavMini: true, isSideNavMini: true });
        setCollapse({ [route["group_name"]]: !collapse[route["group_name"]] });
      }
    }
    if (link_type === "external") {
      window.open(url, "_blank");
    }
    if (link_type === "internal") {
      navigate(pathname);
    }
  };

  const getCollapseState = (colGroup) => {
    let initialState = {};
    Object.values(colGroup).map((rg) => {
      if (rg?.group_type === "collapse") {
        initialState = {
          [rg["group_key"]]: false,
          ...initialState,
        };
      }
    });
    return initialState;
  };

  let hasMyReports = false;
  const [collapse, setCollapse] = useState({});

  if (Object.keys(userReports).length > 0) {
    hasMyReports = true;
  }

  const genRouteGroups = () => {
    let reportGroups = {};
    const totalReports = Object.keys(userRoutes).length;

    for (let i = 0; i <= totalReports; i++) {
      if (userRoutes[i] !== undefined) {
        if (userRoutes[i]?.group_id !== undefined) {
          let grouID = userRoutes[i].group_id;
          reportGroups[grouID] = {
            group_id: userRoutes[i].group_id,
            group_type: userRoutes[i].group_type,
            group_icon: userRoutes[i].group_icon,
            group_key: userRoutes[i].group_key,
            group_name: userRoutes[i].group_name,
          };
        }
      }
    }

    if (hasMyReports) {
      let grouID = myReportsGroup[0].group_id;
      reportGroups[grouID] = {
        group_id: myReportsGroup[0].group_id,
        group_type: myReportsGroup[0].group_type,
        group_icon: myReportsGroup[0].group_icon,
        group_key: myReportsGroup[0].group_key,
        group_name: myReportsGroup[0].group_name,
      };
    }

    return reportGroups;
  };

  let routesGroups = genRouteGroups();

  //this creates the initial collapse state for the groups
  useEffect(() => {
    let initialState = getCollapseState(routesGroups);
    let hasActiveRoute = Object.keys(userRoutes).filter(
      (key) =>
        userRoutes[key].pathname === activeReport &&
        userRoutes[key].group_type === "collapse"
    );

    if (hasMyReports) {
      initialState = { ["myReports"]: false, ...initialState };
    }
    //Handle the opening of the collapse section
    if (hasActiveRoute.length > 0 && !isSideNavMini) {
      let collapseName = userRoutes[hasActiveRoute].group_key;
      initialState = { ...initialState, [`${collapseName}`]: true };
    }

    setCollapse(initialState);
  }, []);

  const handleCollapseClick = (e, group) => {
    e.preventDefault();
    setCollapse({
      ...collapse,
      [group["group_key"]]: !collapse[group["group_key"]],
    });
  };

  // function HandleMyReport(e, route) {
  //   e.preventDefault();
  //   const { links_repo_id } = route;
  //   const routeId = Object.keys(userRoutes).filter(
  //     (key) => userRoutes[key].links_repo_id === links_repo_id
  //   ); //Grab the id of the userRoutes
  //   toggleMyReport(routeId);
  //   updateMyReport(links_repo_id); //Updates the server value
  //   setShowReportNavText(true);
  //   setTimeout(() => {
  //     setShowReportNavText(false);
  //   }, 300);
  // }

  return (
    <div style={{ padding: 0, position: "relative" }}>
      <List>
        <ListItemButton
          onClick={(e) => {
            e.preventDefault();
            setCollapse({ looker: !collapse["looker"] });
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...styles.button,
            ...styles.hoverDefault,
            minHeight: ".5rem",
            margin: sideNavMini ? "0.2rem 0.2rem" : "0.2rem 0.7rem",
          }}
        >
          <ListItemAvatar sx={{ ...styles.avatarListItem, display: "flex" }}>
            <img
              src="/images/looker.svg"
              style={{ width: 24, height: 24, filter: "invert(1)" }}
            />
          </ListItemAvatar>
          <ListItemText>Looker</ListItemText>
          {collapse["looker"] ? (
            <ExpandLess sx={{ fontSize: "1.25rem" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "1.25rem" }} />
          )}
        </ListItemButton>
        <Collapse in={collapse["looker"]} timeout="auto" unmountOnExit={false}>
          <List component="div" disablePadding>
            {lookerRoutes.map((route) => (
              <div key={`${route.slug}`}>
                <ListItemButton
                  onClick={(e) => {
                    e.preventDefault();
                    setCollapse({
                      ...collapse,
                      [`looker_${route.slug}`]:
                        !collapse[`looker_${route.slug}`],
                    });
                  }}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: 4,
                    // ...styles.insideButton,
                    ...styles.hoverIndustrious,
                    ...(activeReport === route.pathname
                      ? {
                          outline: "none",
                          backgroundColor: IndustriousColor,
                          boxShadow: "none",
                        }
                      : ""),
                  }}
                >
                  <ListItemAvatar
                    sx={{ ...styles.avatarListItem, display: "flex" }}
                  >
                    <Icon
                      sx={{
                        width: "1.9rem",
                        height: "1.9rem",
                        alignContent: "center",
                        // color: rg.group_icon === "star" ? "#FFD64F" : "white",
                      }}
                    >
                      {route.icon}
                    </Icon>
                  </ListItemAvatar>
                  <ListItemText>{route.label}</ListItemText>
                  {collapse[`looker_${route.slug}`] ? (
                    <ExpandLess sx={{ fontSize: "1.25rem" }} />
                  ) : (
                    <ExpandMore sx={{ fontSize: "1.25rem" }} />
                  )}
                </ListItemButton>
                <Collapse
                  in={collapse[`looker_${route.slug}`]}
                  timeout="auto"
                  unmountOnExit={false}
                >
                  <List component="div" disablePadding>
                    {route.items?.map((subItem) => (
                      <ListItemButton
                        key={`${subItem.slug}`}
                        onClick={(e) => {
                          e.preventDefault();
                          if (subItem.disabled === true) {
                            return;
                          }
                          navigate(`/looker/${route.slug}/${subItem.slug}`);
                        }}
                        sx={{
                          ...styles.insideButton,
                          ...styles.hoverIndustrious,
                          ...(activeReport === route.pathname
                            ? {
                                outline: "none",
                                backgroundColor: IndustriousColor,
                                boxShadow: "none",
                              }
                            : ""),
                          opacity: subItem.disabled === true ? 0.5 : 1,
                        }}
                      >
                        <ListItemAvatar
                          sx={{ ...styles.avatarListItem, display: "flex" }}
                        >
                          <Icon
                            sx={{
                              width: "1.9rem",
                              height: "1.9rem",
                              alignContent: "center",
                              // color: rg.group_icon === "star" ? "#FFD64F" : "white",
                            }}
                          >
                            {subItem.icon}
                          </Icon>
                        </ListItemAvatar>
                        {subItem.label}{" "}
                        {subItem.disabled === true ? "(Coming Soon)" : ""}
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </div>
            ))}
          </List>
        </Collapse>
        <Divider className={classes.dividers} />
      </List>
      <List>
        <ListItemButton
          onClick={(e) => {
            e.preventDefault();
            setCollapse({ tableau: !collapse["tableau"] });
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...styles.button,
            ...styles.hoverDefault,
            minHeight: ".5rem",
            margin: sideNavMini ? "0.2rem 0.2rem" : "0.2rem 0.7rem",
          }}
        >
          <ListItemAvatar sx={{ ...styles.avatarListItem, display: "flex" }}>
            <img
              src="/images/tableau.png"
              style={{ width: 24, height: 24, filter: "invert(1)" }}
            />
          </ListItemAvatar>
          <ListItemText>Tableau</ListItemText>
          {collapse["tableau"] ? (
            <ExpandLess sx={{ fontSize: "1.25rem" }} />
          ) : (
            <ExpandMore sx={{ fontSize: "1.25rem" }} />
          )}
        </ListItemButton>
        <Collapse in={collapse["tableau"]} timeout="auto" unmountOnExit={false}>
          {/*Populate the Links on the Sidebar*/}
          <List component="div" aria-label="Reports_Section" sx={{ p: 0 }}>
            {Object.values(routesGroups).map((rg) => {
              if (rg.group_key === "myReports") {
                return null;
              }
              const groupRoutes = Object.keys(reportRoutes)
                .filter((key) => reportRoutes[key].group_id === rg.group_id)
                .reduce(
                  (res, key) => ((res[key] = reportRoutes[key]), res),
                  {}
                ); //filter to the reports in the current group
              let currentRoutes =
                hasMyReports === true && rg["group_key"] === "myReports"
                  ? userReports
                  : groupRoutes;
              let nonCollapseRoute =
                rg.group_type !== "collapse"
                  ? groupRoutes[Object.keys(groupRoutes)[0]]
                  : undefined;

              let rgReturn = (
                <div key={`${rg.group_key}${rg.group_type}`}>
                  <ListItemButton
                    key={rg.group_key}
                    onClick={(e) => {
                      rg.group_type === "collapse"
                        ? handleCollapseClick(e, rg)
                        : handleNavButtonClick(e, nonCollapseRoute);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      ...styles.button,
                      // backgroundColor:
                      //   showReportNavText && rg.group_name === "My Reports"
                      //     ? "rgba(255, 255, 255, 0.7)"
                      //     : "",
                      ...styles.hoverDefault,
                      minHeight: ".5rem",
                      margin: sideNavMini ? "0.2rem 0.2rem" : "0.2rem 0.7rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ListItemAvatar
                      sx={{ ...styles.avatarListItem, display: "flex" }}
                    >
                      <Icon
                        sx={{
                          width: "1.9rem",
                          height: "1.9rem",
                          alignContent: "center",
                          color: rg.group_icon === "star" ? "#FFD64F" : "white",
                        }}
                      >
                        {rg.group_icon}
                      </Icon>
                    </ListItemAvatar>
                    <ListItemText
                      primary={rg.group_name}
                      disableTypography={true}
                      sx={{
                        ...styles.navText,
                        // fontSize:
                        //   showReportNavText && rg.group_name === "My Reports"
                        //     ? "1.1rem"
                        //     : "0.9rem",
                        // color:
                        //   showReportNavText && rg.group_name === "My Reports"
                        //     ? "#6d696a"
                        //     : "",
                        display: sideNavMini ? "none" : "block",
                      }}
                    />
                    {sideNavMini ? null : rg.group_type === "collapse" ? (
                      collapse[rg["group_key"]] ? (
                        <ExpandLess sx={{ fontSize: "1.25rem" }} />
                      ) : (
                        <ExpandMore sx={{ fontSize: "1.25rem" }} />
                      )
                    ) : null}
                  </ListItemButton>
                  {rg.group_type === "collapse" ? (
                    <Collapse
                      in={collapse[rg["group_key"]]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {Object.values(currentRoutes).map((route) => {
                          let returnValue = (
                            <ListItemButton
                              key={`${route.key}Button`}
                              sx={{
                                ...styles.insideButton,
                                ...styles.hoverIndustrious,
                                ...(activeReport === route.pathname
                                  ? {
                                      outline: "none",
                                      backgroundColor: IndustriousColor,
                                      boxShadow: "none",
                                    }
                                  : ""),
                                paddingTop: 1,
                                paddingBottom: 1,
                                // paddingLeft: 4,
                              }}
                            >
                              <ListItemText
                                primary={route.link_name}
                                onClick={(e) => {
                                  handleNavButtonClick(e, route);
                                }}
                                disableTypography={true}
                                className={classes.itemText}
                              />
                              {/* <IconButton
                                onClick={(e) => {
                                  HandleMyReport(e, route, index);
                                }}
                                sx={{
                                  ...styles.iconButton,
                                  ...styles.hoverDefault,
                                }}
                              >
                                {route.is_my_report ||
                                rg["group_key"] === "myReports" ? (
                                  <Star
                                    sx={{
                                      width: "1.45rem",
                                      height: "1.45rem",
                                      alignContent: "center",
                                      color: "rgba(255, 255, 255, 0.75)",
                                    }}
                                  />
                                ) : (
                                  <StarBorder
                                    sx={{
                                      width: "1.45rem",
                                      height: "1.45rem",
                                      alignContent: "center",
                                    }}
                                  />
                                )}
                              </IconButton> */}
                            </ListItemButton>
                          );
                          return returnValue;
                        })}
                      </List>
                    </Collapse>
                  ) : null}
                </div>
              );
              return rgReturn;
            })}
          </List>
        </Collapse>
      </List>
    </div>
  );
}

function Sidebar() {
  const classes = useStyles();
  const sidebarOpen = useSidebarStore((state) => state.sidebarOpen);
  const sideNavMini = useSidebarStore((state) => state.sideNavMini);
  const isSideNavMini = useSidebarStore((state) => state.isSideNavMini);
  const floatingSidebar = useSidebarStore((state) => state.floatingSidebar);
  const sideBarDefault = useSidebarStore((state) => state.sideBarDefault);
  const toggleSidebar = useSidebarStore((state) => state.toggleSidebar);
  const fetchUser = useOpticStore((state) => state.fetchUser);
  useEffect(() => {
    fetchUser();
  }, []);
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const floatingSidebarStyle = () => ({
    margin: "1rem",
    borderRadius: ".85rem",
    height: `calc(100vh - 2rem)`,
  });
  const NonfloatingSidebarStyle = () => ({
    margin: 0,
    borderRadius: 0,
    height: "100vh",
  });
  const showOnClick = () => ({
    display: {
      xs: sidebarOpen ? "block" : "none",
      xl: sidebarOpen ? "block" : "none",
    },
  });
  const alwaysShow = () => ({ display: "block" });
  const drawerOpenStyles = () => ({ width: "60px", overflow: "hidden" });
  const SideBarSX = () => ({
    border: 0,
    "& .MuiDrawer-paper": {
      width: `${drawerWidth}px`,
      color: whiteColor,
      transitionProperty: "top, bottom, width",
      transitionDuration: ".2s, .2s, .35s",
      transitionTimingFunction: "linear, linear, ease",
      background: `linear-gradient(195deg, #122932, #122932)`,
      ...boxShadow,
      boxSizing: "border-box",
      ...(floatingSidebar ? floatingSidebarStyle() : NonfloatingSidebarStyle()),
      ...(sideNavMini ? drawerOpenStyles() : null),
    },
  });

  return (
    <Box
      component="nav"
      sx={{ width: drawerWidth, flexShrink: { sm: 0 } }}
      aria-label="optics sideNav"
    >
      <Drawer
        container={container}
        variant="permanent"
        anchor="left"
        open={sidebarOpen}
        onClose={toggleSidebar}
        ModalProps={{ keepMounted: true }}
        sx={{ display: "none", ...SideBarSX() }}
      >
        <SideBarBrand />
        <SideBarUser />
        <div className={classes.sidebarWrapper}>
          <SideBarLinks />
        </div>
      </Drawer>
      <Drawer
        onMouseOver={() => {
          isSideNavMini
            ? useSidebarStore.setState({ sideNavMini: false })
            : null;
        }}
        onMouseOut={() => {
          isSideNavMini
            ? useSidebarStore.setState({ sideNavMini: true })
            : null;
        }}
        variant="permanent"
        anchor="left"
        open
        sx={{
          ...(sideBarDefault ? alwaysShow() : showOnClick()),
          ...SideBarSX(),
        }}
      >
        <SideBarBrand />
        <SideBarUser />
        <div className={classes.sidebarWrapper}>
          <SideBarLinks />
        </div>
      </Drawer>
    </Box>
  );
}

Sidebar.defaultProps = { bgColor: "rose" };

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "Industrious",
  ]),
};

export { Sidebar, SideBarHeader };
