import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DashboardLayout from "components/layouts/dashboard";
import axios from "axios";
import { useCurrentLookerRoute } from "hooks/useCurrentLookerRoute";
import { Chip } from "@mui/material";

const additionalIframeParams = {
  theme: "Looker",
};

export default function Looker() {
  const { route } = useCurrentLookerRoute();
  const [currentTab, setCurrentTab] = useState();

  useEffect(() => {
    setCurrentTab(route?.items?.[0]?.slug || null);
  }, [route]);

  return (
    <DashboardLayout>
      {route?.items && (
        <div
          style={{
            display: "flex",
            gap: 5,
            padding: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {route.items.map((tab) => (
            <Chip
              key={tab.slug}
              label={tab.label}
              onClick={() => setCurrentTab(tab.slug)}
              color={tab.slug === currentTab ? "primary" : "default"}
            />
          ))}
        </div>
      )}
      {route?.items ? (
        <Embed
          src={
            route.items.find((tab) => tab.slug === currentTab)?.src ||
            route.items[0]?.src
          }
        />
      ) : (
        <Embed src={route?.src} />
      )}
    </DashboardLayout>
  );
}

Embed.propTypes = {
  src: PropTypes.string,
};

function Embed({ src }) {
  const [iframeUrl, setIframeUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIframeUrl(null);
    if (!src) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const apiURl = process.env.REACT_APP_API_URL;

    const embedSrcUrl = new URL(
      src, // src is something like '/embed/explore/gringotts/sales_funnel_2'
      "http://localhost"
    ); // we don't care about the domain, we just use an URL object to parse the path and query params
    Object.keys(additionalIframeParams).forEach((key) => {
      embedSrcUrl.searchParams.append(key, additionalIframeParams[key]); // e.g. add ?theme=Looker to the query params
    });
    // reconstruct the src with the additional params
    // e.g. '/embed/explore/gringotts/sales_funnel_2' + '?theme=Looker' => '/embed/explore/gringotts/sales_funnel_2?theme=Looker'
    const finalEmbedSrc = embedSrcUrl.pathname + embedSrcUrl.search;

    axios
      .request({
        baseURL: apiURl,
        url: "/looker/auth",
        withCredentials: true,
        params: {
          src: finalEmbedSrc,
        },
      })
      .then((response) => {
        const url = response.data.url;
        setIframeUrl(url);
        setIsLoading(false);
      });
  }, [src]);

  return (
    <div style={{ marginLeft: -26 }}>
      {!src && <div>not configured</div>}
      {src && isLoading && <div>Loading...</div>}
      {iframeUrl && (
        <iframe
          src={iframeUrl}
          style={{ width: "100%", height: "100vh", border: "none" }}
        />
      )}
    </div>
  );
}
