import React, { useState } from "react";
import DashboardLayout from "components/layouts/dashboard";
import axios from "axios";

export default function Looker() {
  const [src, setSrc] = useState("");
  const [iframeUrl, setIframeUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function loadIframe() {
    console.log("Looker Page");
    setIframeUrl(null);
    setIsLoading(true);
    const apiURl = process.env.REACT_APP_API_URL;
    axios
      .request({
        baseURL: apiURl,
        url: "/looker/auth",
        withCredentials: true,
        params: {
          src,
        },
      })
      .then((response) => {
        console.log(response.data);
        const url = response.data.url;
        setIframeUrl(url);
        setIsLoading(false);
      });
  }

  return (
    <DashboardLayout>
      <div>
        <div style={{ display: "flex", gap: 5, padding: 10 }}>
          <input
            type="text"
            value={src}
            onChange={(e) => setSrc(e.target.value)}
            style={{ flexGrow: 1 }}
            placeholder="Enter Looker embed URL"
          />
          <button
            onClick={() => {
              loadIframe();
            }}
          >
            Load IFrame
          </button>
        </div>
        {isLoading && <div>Loading...</div>}
        {iframeUrl && (
          <iframe
            src={iframeUrl}
            style={{ width: "100%", height: "100vh", border: "none" }}
          />
        )}
      </div>
    </DashboardLayout>
  );
}
